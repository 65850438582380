import React from 'react';
import FeatureFlags from '../Infrastructure/FeatureFlags/FeatureFlags';
import useFeatureFlag from '../Infrastructure/FeatureFlags/useFeatureFlag';

import Default from './Default';
import styles from './styles.css';
import ReferralCampaign from './ReferralCampaign';
import PlanningBooster from './PlanningBooster';
import WorkSmarter from "./Worksmarter";

const MarketingBanner = () => {
  const bannerType = useFeatureFlag(FeatureFlags.MarketingBanner);

  switch (bannerType) {
    case 'referral-campaign':
      return <ReferralCampaign />;
    case 'planning-booster':
      return <PlanningBooster />;
    case 'work-smarter':
      return <WorkSmarter />;
    default:
      return <Default />;
  }
};

const MarketingBanners = () => {
  return (
    <div className={styles['marketing-banners']}>
      <MarketingBanner />
    </div>
  );
};

export default MarketingBanners;
