import post from '../post';

type RequestResetResponse = string;

interface RequestResetRequest {
  email: string;
  recaptchaToken?: string;
}

const requestReset = async ({ email, recaptchaToken }: RequestResetRequest) =>
  post<RequestResetResponse>('/password.requestReset', { email }, { 'G-Recaptcha-Response': recaptchaToken });

export default requestReset;
