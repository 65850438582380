import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import App from './App';

Sentry.init({
  dsn: __CONFIG__.sentry.dsn,
  enabled: __CONFIG__.sentry.enabled,
  environment: __CONFIG__.sentry.environment,
  release: `${__CONFIG__.sentry.projectName}@${__VERSION__ || ''}`,
  beforeSend(event, hint) {
    // Filter out Recaptcha related rejection errors.
    // See https://github.com/getsentry/sentry-javascript/issues/2514
    if (hint.originalException === 'Timeout') return null;
    return event;
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
