import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { translate } from '@teamleader/i18n';
import { Button, TextSmall, TextBody, Heading1, Input, Label, Link, IconArrowLeftSmallOutline } from '@teamleader/ahoy';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import API from '../../Infrastructure/API';
import { getFormFieldErrorFromMeta } from '../../Infrastructure/Forms/utils';
import useQuery from '../../Infrastructure/Routing/useQuery';

interface FormValues {
  email?: string;
}

const ForgotPassword = () => {
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const query = useQuery();
  const prefilledEmail = query.get('email');

  const handleSubmit = async (values: FormValues) => {
    setIsSuccessMessageVisible(false);

    try {
      const { recaptchaKey } = __CONFIG__;
      if (recaptchaKey) {
        window.grecaptcha.enterprise.ready(async () => {
          const recaptchaToken = await window.grecaptcha.enterprise.execute(recaptchaKey, {
            action: 'auth/requestPasswordReset',
          });
          await API.password.requestReset({
            email: values.email!,
            recaptchaToken,
          });
        });
      } else {
        await API.password.requestReset({
          email: values.email!,
        });
      }
    } catch (error) {
      return { [FORM_ERROR]: translate('forgotPasswordError.generic') };
    }

    setIsSuccessMessageVisible(true);
  };

  const validate = (values: FormValues) => {
    const errors: Record<string, boolean | string> = {};

    if (!values.email || values.email.trim().length === 0) {
      errors.email = true;
    }

    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit, submitError, submitting }) => (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit}>
          <Heading1 marginBottom={3}>{translate('forgotPasswordQuestion')}</Heading1>

          <TextBody color="neutral" tint="darkest" marginBottom={3}>
            {translate('forgotPasswordDescription')}
          </TextBody>

          <Field name="email" initialValue={prefilledEmail || ''}>
            {({ input, meta }) => {
              return (
                <Label marginBottom={3}>
                  {translate('email')}
                  <Input
                    type="email"
                    placeholder={translate('email')}
                    autoFocus
                    {...input}
                    error={getFormFieldErrorFromMeta(meta)}
                    size="large"
                  />
                </Label>
              );
            }}
          </Field>

          {submitError && (
            <TextSmall marginVertical={3} color="ruby" tint="dark">
              {submitError}
            </TextSmall>
          )}

          {isSuccessMessageVisible && (
            <TextSmall marginVertical={3} color="mint" tint="dark">
              {translate('forgotPasswordSuccessMessage')}
            </TextSmall>
          )}

          <Button
            size="large"
            level="primary"
            fullWidth
            marginTop={3}
            marginBottom={5}
            type="submit"
            processing={submitting}
          >
            {translate('sendResetLink')}
          </Button>

          <Link to={`/?${query.toString()}`} inherit={false} element={RouterLink}>
            <IconArrowLeftSmallOutline />
            <span>{translate('goBack')}</span>
          </Link>
        </form>
      )}
    />
  );
};

export default ForgotPassword;
