import post from '../post';

type LoginResponse = string;

interface LoginRequest {
  email: string;
  password: string;
  token?: string;
  trusted?: boolean;
}

interface RateLimitingData {
  attempts: {
    allowed: number;
    left: number;
    window: {
      unit: string;
      value: number;
    };
  };
}

export interface LoginInvalidCredentialsErrorResponse {
  meta: RateLimitingData;
}

export interface LoginTooManyAttemptsErrorResponse {
  meta: RateLimitingData;
}

const login = async ({ email, password, token, trusted }: LoginRequest) =>
  post<LoginResponse>('/users.login', { email, password, token, trusted });

export default login;
